import React, { Component } from "react"
import { useState, useEffect, useRef } from "react"
import remark from 'remark';
import remarkHtml from 'remark-html';
import sanitize from 'rehype-sanitize';

export default function TopHeader({ client }) {
  const [shownTitle, setTitle] = useState(null)
  const [shownDescription, setDescription] = useState(null)
  const [shownAnnouncement, setAnnouncment] = useState(null)
  const [primaryColour, setPrimaryColour] = useState(null)
  const dt = useRef(null)
  
 useEffect(()=>{

  fetch('https://websitefrontendapi.azurewebsites.net/api/GetSetup?name'+client)
      .then(response => response.json())
      .then(res => {
          setTitle(res.items[0].fields.websiteHeader);
          setDescription(res.items[0].fields.descriptionBeginningOfWebsite);
          setAnnouncment(res.items[0].fields.topAnnouncement);
          setPrimaryColour(res.items[0].fields.primaryColour);
        })
      .catch(console.log)
 }, []); 


  return (
    <div>
    <p><div  style={{
      background: primaryColour,
      marginBottom: `1.45rem`,
      margin: `0 auto`,
     
        padding: `1.45rem 1.0875rem`,
        color: `white`,
        textDecoration: `none`,
    }} 
    
    
    dangerouslySetInnerHTML={{ __html:
            
        remark().use(remarkHtml)
            .processSync(shownAnnouncement).toString() }} /></p>

    <h1>{(shownTitle ? shownTitle : "Loading...")}</h1>
        <p><div dangerouslySetInnerHTML={{ __html:
            
            remark().use(remarkHtml)
                .processSync(shownDescription).toString() }} /></p>
        
     </div>
  )
}
