import React from "react"
import { Link } from "gatsby"
import remark from 'remark';
import remarkHtml from 'remark-html';
import sanitize from 'rehype-sanitize';

import Layout from "../components/layout"

import MainTable from "../components/table"
import TopHeader from "../components/topheader"
import websiteData from "../data/setup.json"

const setupData = websiteData.items[0].fields
const gh = require('hast-util-sanitize/lib/github');

var description = remark().use(remarkHtml)
      .processSync(setupData.descriptionBeginningOfWebsite).toString();

const newLocal = 0;
const IndexPage = (props) => 
{
  const { location } = props;
  const pathname = location.href;
  const hostn = location.hostname;
return (
  <Layout>
  
  <TopHeader client={1}></TopHeader> 
    
    <br/> <br/> 
   
 
    <div className="p-card-content p-shadow-2">
    <MainTable client={1}></MainTable>
    {pathname}<br/>
    {hostn}
    </div>
    <br/> <br/>  

      <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
        </Link>
  </Layout>
)
}
export default IndexPage

