import React, { Component } from "react"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { Button } from "primereact/button"
import { InputText } from "primereact/inputtext"
import { useState, useEffect, useRef } from "react"


import "primereact/resources/themes/saga-blue/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import "primereact/resources/themes/mdc-light-indigo/theme.css"
import "./table.css"

export default function DataTableSortDemo({ client }) {
  const [globalFilter, setGlobalFilter] = useState(null)
  const [loading, setLoading] = useState(false);
  const [shownData, setShownData] = useState(null)

  const dt = useRef(null) 

 useEffect(()=>{

  fetch("https://websitefrontendapi.azurewebsites.net/api/GetDocuments?name="+client)
      .then(res => res.json())
      .then(newdata => setShownData( newdata ))
      .catch(console.log)
 }, []);

  const header = (
    <div className="table-header p-fluid">
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={e => setGlobalFilter(e.target.value)}
          placeholder="Search for any values"
        />
      </span>
    </div>
  )

  const actionBodyTemplate = rowData => {
    return (
      <a href={rowData.document.url ? rowData.document.url : rowData.document.file.url }>
        <Button
          type="button"
          icon="pi pi-download"
          className="p-button-secondary p-link"
          tooltip="Download"
          tooltipOptions={{ showDelay: 1000, hideDelay: 300 }}
        ></Button>
      </a>
    )
  }

  const changeDateFormat = value => {
    if (value) 
    {
      var date = new Date(value);
      return date.toLocaleDateString();  
    }
    else
    {
      return "";
    }
     
    return date.toLocaleDateString();
  }

  const formatedDate = rowData => {

    return changeDateFormat(rowData.officialPublicationDate);
  }

  return (
    <div className="p-card-content p-shadow-2 datatable-responsive-demo">
      <DataTable
        ref={dt}
        header={header}
        value={shownData}
        globalFilter={globalFilter}
        emptyMessage="Loading your documents..."
        paginator rows={20}
        className="p-datatable-responsive-demo"
      >
        <Column
          field="officialPublicationDate"
          header="Published"
          body = {formatedDate}
          sortable
        ></Column>
        <Column field="title" header="Title" sortable></Column>
        <Column field="documentType" header="Type" sortable></Column>
        <Column field="company" header="Company" sortable></Column>
        <Column field="legalIdentifiers" header="Identifier" sortable></Column>
        <Column
          body={actionBodyTemplate}
          headerStyle={{ width: "8em", textAlign: "center" }}
          bodyStyle={{ textAlign: "center", overflow: "visible" }}
        />
      </DataTable>
    </div>
  )
}
